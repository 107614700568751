@font-face {
  font-family: Gilroy;
  src: url("./assets/fonts/Gilroy-Regular.ttf");
}
@font-face {
  font-family: GilroyMed;
  src: url("./assets/fonts/Gilroy-Medium.ttf");
}

* {
  font-family: "Gilroy";
  font-weight: 500;
  background: #ffffff;
  user-select: none;
}

html {
  height: 100%;
  padding: 0;
  margin: 0;
}
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

#root {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f1f1f1 !important;
  overflow: hidden;
}

.landscape-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: black;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
}
