.loader {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
}

.hide-loader {
  display: none;
}

.loader > #circle {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 5px solid #ffc727;
  border-right: 5px solid white;
  margin: auto;
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
