.form-input {
  display: flex;
  width: calc(100% - 30px);
  padding: 10px 15px;
  margin-top: 10px;
  border-radius: 18px;
  background: #fafafa;
}

.form-input > .img {
  display: flex;
  width: 25px;
  height: 25px;
  margin: auto 0;
  padding: 5px;
  border-radius: 100%;
}

.form-input > .img > img {
  margin: auto;
  width: 90%;
  height: 90%;
}

.form-input > .form-input-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 15px);
  padding-left: 15px;
  background-color: transparent;
  margin: auto 0;
}

.form-input > .form-input-field > label {
  font-size: 0.625rem;
  color: rgba(130, 130, 130, 1);
  background-color: transparent;
}

.form-input > .form-input-field > input {
  padding: 0;
  font-size: 1rem;
  font-style: normal;
  outline-style: none;
  border: transparent;
  background-color: transparent;
}

/*input style 2*/
.form-input-II {
  display: flex;
  width: calc(100% - 30px);
  padding: 15px;
  margin-top: 10px;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 18px;
  background: rgba(252, 252, 252, 1);
}
.form-input-II > img {
  width: 20px;
  height: 20px;
  margin: auto 0;
  padding: 5px 15px 5px 0;
  border-right: 2px solid rgba(217, 217, 217, 1);
  background: transparent;
}

.form-input-II > .form-input-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 15px);
  padding-left: 15px;
  background-color: transparent;
  margin: auto 0;
}

.form-input-II > .form-input-field > label {
  font-size: 0.625rem;
  color: rgba(130, 130, 130, 1);
  background-color: transparent;
}

.form-input-II > .form-input-field > input {
  padding: 0;
  font-size: 1rem;
  font-style: normal;
  outline-style: none;
  border: transparent;
  background-color: transparent;
  color: #3e3e3e;
}

/**Selector Styling**/
.selector {
  position: relative;
  width: 100%;
  border-radius: 18px;
  margin: auto 0 auto 2px;
}
.selector > .s-body {
  width: 90%;
  padding: 5%;
  display: flex;
  background: #f3f3f3;
  border-radius: 10px;
}
.selector > .s-body > img {
  width: 15px;
  background: transparent;
}
.selector > .s-body > img:nth-child(3) {
  width: 10px;
  margin-left: auto;
  transition: 0.4s;
}
.selector > .s-body > span {
  margin: auto 8px;
  font-size: 0.8rem;
  background: transparent;
}

.selector > .options {
  width: 100%;
  left: 0;
  /* bottom: -120px; */
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08);
  transition: 0.4s;
  z-index: 2;
}

.selector > .options > li {
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding: 5%;
  font-size: 0.8rem;
  cursor: pointer;
}

.selector > .options > li > label {
  margin: auto 0;
}

.selector > .options > li > img {
  width: 15px;
  height: 15px;
  margin: auto 0;
}

.selector > .options > .selected {
  opacity: 0.6;
}

/*selector input stylings*/
.selector-input {
  width: 90%;
  padding: 5%;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 60% 40%;
  background: #fcfcfc;
  border-radius: 18px;
}

.selector-input-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 8px);
  background-color: transparent;
  margin: auto 8px auto 0;
}

.selector-input-field.border-right {
  border-right: 1px solid #d9d9d9;
}

.selector-input-field > label {
  font-size: 0.625rem;
  color: rgba(130, 130, 130, 1);
  background-color: transparent;
}

.selector-input-field > input {
  padding: 0;
  font-size: 0.9rem;
  font-style: normal;
  font-family: "GilroyMed";
  outline-style: none;
  border: transparent;
  background-color: transparent;
}

.selector-input-field > select {
  padding: 0;
  font-size: 0.9rem;
  font-style: normal;
  font-family: "GilroyMed";
  outline-style: none;
  border: transparent;
  background-color: transparent;
}

/*button stylings*/
.form-button {
  display: flex;
  width: fit-content;
  justify-content: center;
  cursor: pointer;
}
.form-button:hover {
  filter: invert(100%);
}

.disabled {
  filter: invert(100%);
}

.form-button > button {
  display: block;
  border: transparent;
  background-color: transparent;
  color: white;
  cursor: pointer;
}

.form-button > img {
  background: transparent;
}

.loginBtn {
  width: 30%;
  background-color: rgba(65, 65, 65, 1);
  padding: 15px 25px;
  border-radius: 25px;
  margin: auto;
}

.conferenceBtn {
  background-color: #ffc727;
  padding: 14px 15px;
  white-space: nowrap;
  height: fit-content;
  margin-left: 30px;
}

.modalBtn {
  font-family: "GilroyMed";
  font-size: 1rem;
  background-color: #ffc727;
  padding: 10px 18px;
  white-space: nowrap;
  height: fit-content;
  margin: 30px auto 15px auto;
  border-radius: 10px;
}

.exportBtn {
  font-size: 1rem;
  white-space: nowrap;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 11px;
  background-color: #424242;
  height: fit-content;
  margin: auto 0 auto 30px;
}

.acqNoteBtn {
  width: fit-content;
  padding: 4.5px;
  border-radius: 5px;
  background-color: #ffe8b2;
  height: fit-content;
  margin: auto 0 auto auto;
}
.acqNoteBtn > button {
  display: block;
  margin: auto 0;
  padding: 0 5px 0 0;
  font-family: GilroyMed;
  font-size: 0.8rem;
  color: #f7ad00;
  white-space: nowrap;
}
.acqNoteBtn > img {
  width: 13px;
  height: 14.25px;
  margin: auto 0;
}

.no-effect:hover {
  filter: invert(0);
}

/**file input*/
.file-input {
  position: relative;
  padding: 10px 20px;
  border-radius: 11px;
  background-color: #424242;
  margin: auto 0 auto 30px;
  height: fit-content;
}
.file-input > input {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
}
.file-input > .label {
  background: transparent;
  display: flex;
}
.file-input > .label > label {
  background: transparent;
  white-space: nowrap;
  font-size: 0.9rem;
  font-weight: 500;
  color: #ffffff;
  margin: auto 5px auto 0;
}
.file-input > .label > img {
  background: transparent;
}

.note {
  width: 95%;
  padding: 2.5%;
  margin-top: 10px;
  background: #f8f8f8;
  border-radius: 7px;
}
.note > textarea {
  outline-style: none;
  width: 100%;
  height: 100px;
  background-color: transparent;
  border: transparent;
  resize: none;
}
.note > textarea::placeholder {
  color: #acacac;
}
.note > span {
  background: transparent;
  width: fit-content;
  display: block;
  margin: 10px 0 0 auto;
  color: #414141;
  font-size: 0.81rem;
}

.Input2-error_text {
  font-size: 14px;
  margin-bottom: -12px;
  margin-top: 5px;
  color: red;
}

.Input2-error_text1 {
  font-size: 14px;
  margin-bottom: -12px;
  margin-top: 9px;
  color: green;
}

.Input2-error_text2 {
  font-size: 14px;
  margin-bottom: -12px;
  margin-top: 10px;
  color: green;
}
