.profile-wrapper {
  width: 90%;
  padding: 5%;
}

.profile-wrapper > p {
  text-align: center;
  font-family: "Gilroy";
  font-weight: 400;
  font-size: 1.2rem;
  margin: 20px 0 0 0;
  color: #828282;
}

.profile {
  position: relative;
  width: fit-content;
  height: fit-content;
  margin: auto;
}
.profile > .border {
  width: 170px;
  height: 170px;
  padding: 5px;
  margin: 0;
  border-radius: 100%;
  border: 2px dashed #f7ad00;
}
.profile > .border > img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.profile > .upload {
  bottom: calc(0px - 15.5px);
  left: calc(50% - 20px);
  position: absolute;
  background: transparent;
}
.profile > .upload > .img {
  display: flex;
  width: 21px;
  height: 22px;
  padding: 9px 10px;
  background: #060606;
  border-radius: 100%;
}
.profile > .upload > .img > img {
  width: 100%;
  height: 100%;
  background: transparent;
  margin: auto;
}

.profile > .upload > input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/*profile-container*/
.profile-container {
  width: 90%;
  margin: 20px auto;
}
.profile-container > h4 {
  display: block;
  font-family: "GilroyMed";
  font-weight: 500;
  font-size: 0.9rem;
}

@media only screen and (min-width: 700px) {
  .profile-container > div {
    display: grid;
    grid-template-columns: 47.5% 47.5%;
    grid-gap: 5%;
  }
}

@media only screen and (min-width: 1000px) {
  .profile-container > div {
    grid-template-columns: 32% 32% 32%;
    grid-gap: 2%;
  }
}
