.container {
  padding-top: 1rem;
  margin: 2rem;
}

.text-warning {
  color: #ffc107;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.chat-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 450px;
  overflow-y: scroll;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.9rem;
  padding: 0.9rem;
}

.chat-message {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.textBoxRight {
  background-color:#fff;
}

.textBoxLeft {
  background-color:  #e2ffc4;
}

.chat-message-left {
  background-color: white;
  border: 1px solid #ced4da;
  width: 160px;
  border-radius: 15px;
  align-self: flex-start;
  border-top-left-radius: 0px;
}

.chat-message-right {
  background-color: #e2ffc4;
  border: 1px solid #ced4da;
  width: 160px;
  border-radius: 15px;
  align-self: flex-end;
  border-top-right-radius: 0px;
}

/* .chat-box {
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .90rem;
    padding: 0.90rem;
    height: 450px;
    overflow-y: scroll;
  }
  
  .chat-message {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
  }
  
  .chat-message-right {
    background-color: #fff;
    align-self: flex-end;
    border: 1px solid #ced4da;
    width: 160px;
    border-radius: 14px;
  }
  
  .chat-message-left {
    background-color: #fff;
    align-self: flex-start;
    border: 1px solid #ced4da;
    width: 160px;
    border-radius: 14px;
  }
   */
.chat-message strong {
  font-weight: bold;
}

.chat-message small {
  color: #6c757d;
  font-size: 11px;
  margin-left: 7px;
}

.chat-message h4 {
  margin-top: 6px;
  margin-bottom: 10px;
  font-size: 14px;
}

.form-group {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.form-control {
  flex-grow: 1;

  border-radius: 0.25rem;
  height: 40px;
  border: 1px solid #ced4da;
}

.btn {
  border-radius: 0.25rem;
  background-color: #ffc107;
  color: #fff;
}

.btn-warning {
  background-color: #ffc107;
}

.mx-2 {
  margin-left: 0.5rem;
  padding: 11px 33px;
  border: none;
  font-size: 17px;
}

.back-btn {
  color: #000000;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 0rem;
    margin: 1rem;
    margin-top: 0;
  }

  .form-control {
    max-width: 75%;
    padding-left: 10px;
  }

  .mx-2 {
    max-width: 25%;
  }
}

@media screen and (max-width: 420px) {
  .mx-2 {
    padding: 11px 20px;
  }
}
