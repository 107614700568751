.conference-card {
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding: 5%;
  margin: 15px 0;
  background-color: #f8f8f8;
  border-radius: 11px;
}

.img-container {
  display: flex;
  width: 120px;
  height: 150px;
  margin: 20px 0;
}

.img-container > img {
  width: 100%;
  height: 100%;
  transition: all;
  border-radius: 5px;
}

.img-container-zoom {
  width: 90%;
  height: 80%;
  margin: auto;
}

.details {
  background: transparent;
}

.details > h5 {
  flex: 1;
  white-space: wrap;
  background: transparent;
  margin: 0;
  font-size: 1.4375rem;
  font-weight: 600;
  color: #ffc727;
}

.details > .content {
  list-style: none;
  width: 90%;
  padding: 0;
  margin: 13px 0 21px 0;
  background: transparent;
}

.details > .content > li {
  display: flex;
  width: 100%;
  margin: 15px 0;
  white-space: wrap;
  background: transparent;
  color: #000000;
}

.details > .content > li > img {
  width: 18px;
  height: 18px;
  margin: 0 10px auto 0;
  background: transparent;
}
.details > .content > li > span {
  font-size: 14px;
  font-family: Gilroy;
  font-weight: 400;
  color: #000000;
  margin: auto 0;
  background: transparent;
}

.conference-logo {
  height: 50%;
  margin: auto 0;
}

.list {
  display: flex;
  width: 100%;
  padding: 5% 0;
  border-bottom: 2px solid #eff1f3;
}

.list > .icon {
  margin: auto 10px auto 0;
}
.list > .nav {
  margin: auto 0 auto auto;
  padding: 0 8px;
  cursor: pointer;
}

.list > h5 {
  margin: auto 0;
  font-size: 1rem;
  color: #414141;
}
/*conference sponsors UI**/
.sponsor {
  overflow-y: scroll;
}

.spr-list {
  display: flex;
  width: 100%;
  padding: 2.5% 0;
  border-bottom: 2px solid #eff1f3;
}
/* Image size updated */
.spr-list > .spr-icon {
  width: 8rem;
  height: auto;
  margin: auto 35px auto 0;
}
.spr-list > h5 {
  margin: auto 0;
  font-family: GilroyMed;
  font-weight: 500;
  font-size: 0.81rem;
  color: #000000;
}

/*conference time UI*/
.conference-time {
  display: flex;
  font-family: GilroyMed;
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0 0 auto 0;
  background: transparent;
}

.conference-time > .status {
  width: fit-content;
  padding: 6px 13px;
  font-family: GilroyMed;
  font-size: 0.9rem;
  border-radius: 4px;
  color: #414141;
}
.conference-time > .not-started {
  background: #a9c6ff;
}
.conference-time > .open {
  background: #c0ffa9;
}
.conference-time > .closed {
  background: #ff9393;
}
.conference-time > label {
  margin: auto 0;
  font-size: 0.9rem;
  font-family: GilroyMed;
  color: rgba(65, 65, 65, 1);
}
.conference-time > .dot {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin: auto 10px;
  background: #d0d6db;
}

/*participants UI*/
.conference-participants {
  flex: 1;
  display: flex;
  cursor: pointer;
}
.conference-participants > .p-profiles {
  width: 100px;
  display: flex;
  margin: auto 0;
}
.conference-participants > .p-counts {
  margin: auto 0 auto 10px;
  color: #414141;
  font-size: 1rem;
}
.participant {
  position: relative;
  min-width: 22px;
  max-width: 22px;
  height: 22px;
  top: 0;
  padding: 2.5px;
  border-radius: 100%;
  background-color: #ffffff;
}
.participant > img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

/* Resource */

ol {
  /* background: #f7ad00b8; */
  padding: 20px;
}

ol li {
  /* background: #F7AD00; */
  color: #af7c04;
  padding: 5px;
  margin-left: 35px;
  font-weight: bold;
  font-size: 19px;
}

.workshop_materials_text {
  color: #af7c04 !important;
  text-decoration: none !important;
  font-size: 20px;
}
