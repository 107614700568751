.panel {
  flex: 1;
  width: 90%;
  height: 94.918%;
  min-width: 90%;
  padding: 5%;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.panel > .panel-tabs {
  display: flex;
  justify-content: space-between;
}
.panel > .panel-tabs > .tab {
  width: fit-content;
  padding: 5px 8px;
  color: #acacac;
  font-size: 1rem;
  font-family: GilroyMed;
  font-weight: 500;
}
.panel > .panel-tabs > .active {
  border-bottom: 2px solid #f7ad00;
  color: #414141;
}

.panel > .panel-pages {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  height:100%;
  overflow: hidden;
  scroll-behavior: smooth;
  padding-bottom: 10px;
}
.panel > .panel-pages > .page {
  min-width: 100%;
  height: inherit;
  margin-top: 15px;
}

.panel > .panel-pages > .page::-webkit-scrollbar {
  
}