.agendas {
  overflow-y: scroll;
}
.agenda-nav {
  display: flex;
  width: calc(90% - 2px);
  padding: 5%;
  border: 1px solid #eff1f3;
  border-radius: 11px;
}

.agenda-nav > .stack {
  padding: 15px;
  border-radius: 6px;
  width: 20px;
  height: 20px;
  background: #ffd93b;
  margin: auto 10px auto 0;
}

.agenda-nav > p {
  margin: auto 0;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  color: #000000;
}
.agenda-nav > p > span {
  padding: 5px;
  background: #efefef;
  border-radius: 2px;
  margin-left: 10px;
}

.agenda-nav > .right {
  margin-left: auto;
}

/*agena*/
.agenda {
  width: 100%;
}
.agenda > h5 {
  font-family: Gilroy;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 0;
}
.agenda > .type {
  width: 100%;
  margin: 0;
  padding: 0;
}
.agenda > .type > h3 {
  font-family: Gilroy;
  font-size: 19px;
  margin: 10px 0;
  font-weight: 600;
}

.type > .topic {
  position: relative;
  display: flex;
  padding: 15px 0;
}
.type > .topic {
  border-bottom: 2px solid #eff1f3;
  overflow: hidden;
}
.type > .topic > .time {
  /* width: 85px; */
  width: 120px;
  height: fit-content;
  word-wrap: normal;
  padding: 13px 0;
  margin: auto 1px;
  text-align: center;
  border-radius: 6px;
  background: #ff3d3d;
  font-family: "Gilroy";
  font-size: 13px;
  font-weight: 500;
  line-height: 10px;
  color: #ffffff;
}
.type > .topic > .content {
  flex: 1;
  white-space: pre-wrap;
  margin: auto 0 auto 10px;
}
.type > .topic > .content > p {
  font-family: "Gilroy";
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
}
.type > .topic > .content > .address {
  font-weight: 400;
}
.type > .topic > .action {
  display: flex;
  margin-left: auto;
}
.type > .topic > .action > img {
  margin: auto 5px;
}
.topic > div:nth-child(1) {
  display: none;
}
.topic-loader > div:nth-child(1) {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.5;
  z-index: 1;
}

.add-to-calendar > .form-button > button {
  background-color: #fbbf24 !important; /* Yellow color */
  color: #ffffff; /* White color for text */
  border: none; /* Remove the default border */
  border-radius: 5px; /* Rounded corners with 5px border-radius */
  padding: 10px 20px; /* Adjust the padding as needed */
  font-size: 16px; /* Adjust the font size as needed */
  cursor: pointer; /* Show the pointer cursor on hover */
}

/* Style the button on hover */
.add-to-calendar > .form-button > button:hover {
  background-color: #fbbf24 !important; /* Yellow color */
  border: none; /* Remove the default border */
  border-radius: 5px; /* Rounded corners with 5px border-radius */
}

/* Add this to your Agendas.css stylesheet */

.dropdown-button {
  background: none;
  border: none;
  color: #000000;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  transition: background-color 0.3s, color 0.3s;
}

.dropdown-button.expanded {
  /* background-color: #fbbf24;
  color: #ffffff; */
}

.arrow-icon {
  width: 18px;
  height: 18px;
  margin-left: 8px;
  transition: transform 0.3s;
}

.dropdown-button.expanded .arrow-icon {
  transform: rotate(180deg);
}

@media (max-width: 480px) {
  .type > .topic > .action {
    flex-wrap: wrap;
    flex-basis: 100%;
    margin-top: 10px;
  }
  .add-to-calendar > .form-button > button {
    padding: 6px 14px; /* Adjust the padding for smaller screens */
    font-size: 12px; /* Adjust the font size for smaller screens */
  }

  .add-to-calendar {
    flex-basis: 100%;
    margin-top: 10px;
  }

  .type > .topic {
    flex-wrap: wrap;
  }
}

.label_agenda_pst{
  font-size: 18px;
  margin-top: 30px;
  font-weight: bold;
}

.hideScroll{
  overflow-y: hidden!important;
}