.popup-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding: 0;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.4s;
  z-index: 2;
}

.popup-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  width: 90%;
  height: 300px;
  padding: 0;
  border-radius: 30px 30px 0 0;
  background: #feffff;
  bottom: 0;
  transition: 0.4s;
  z-index: 6;
  overflow-y: auto;
}

.popup-head {
  display: flex;
  justify-content: center;
  text-align: center;
}

.popup-head > span {
  margin: auto 0 auto 10px;
  font-family: GilroyMed;
  font-size: 1rem;
  color: #f7ad00;
  white-space: nowrap;
}

.popup-message-modal {
  position: absolute;
  top: 15px;
  right: -100%;
  display: flex;
  width: 50%;
  max-width: 280px;
  padding: 11px 16px;
  border-radius: 50px;
  transition: all 0.4s;
  z-index: 2;
}
.popup-message-modal > p {
  background-color: transparent;
  color: white;
  font-size: 0.9rem;
  font-weight: 600;
  margin: auto 0;
  white-space: pre-wrap;
}
.popup-message-modal > img {
  background: transparent;
  margin-right: 16px;
}

.popup-message-failed {
  background-color: #ff6262;
  right: 15px;
}

.popup-message-success {
  background-color: green;
  right: 15px;
}
