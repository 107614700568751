.acquaintances {
  margin-top: 10px;
}
.acq-list {
  display: flex;
  width: 95%;
  padding: 2.5%;
  border-bottom: 2px solid #eff1f3;
}
.acq-list > .acq-icon {
  width: 50px;
  height: 50px;
  border-radius: 6px;
  margin: auto 15px auto 0;
}
.acq-list > .acq-nav {
  margin: auto 0 auto 20px;
  padding: 0;
  cursor: pointer;
}
.acq-list > .acq-details {
  margin: auto 0;
}
.acq-list > .acq-details > h5 {
  margin: 0;
  font-family: Gilroy;
  font-weight: 500;
  font-size: 0.9rem;
  color: #000000;
}

.acq-list > .acq-details > p {
  margin: 0;
  font-family: Gilroy;
  font-size: 0.8rem;
  color: #a0a0a0;
}

.atag{
  text-decoration: none !important;
  background-color: transparent !important;
  color: white !important;
}