.chat-button {
    background-color: transparent;
    color: rgb(97%, 68%, 0%);
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    border: 1px solid rgb(97%, 68%, 0%);
}

.chat-button:hover {
    background-color: rgb(97%, 68%, 0%, 0.3);
}

.chat-icon {
    font-size: 20px;
}

.chat-text {
    margin-left: 5px;
}
