.cover-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.cover-image {
  width: 100%;
  height: 140px;
  padding: 0;
  margin: 0;
}
.cover-image > img {
  width: 100%;
  height: 100%;
}

.cover-profile {
  width: 100%;
  text-align: center;
  background: transparent;
  transform: translateY(-60px);
}
.cover-profile > img {
  width: 120px;
  height: 120px;
  aspect-ratio: 1/1;
  border-radius: 100%;
}
.cover-profile > h4 {
  margin: 10px 0;
  font-family: GilroyMed;
}

.socials {
  display: flex;
  justify-content: center;
}
.social-link {
  display: flex;
  min-width: 24px;
  height: 24px;
  padding: 5px;
  margin-right: 5px;
  border: 1.53672px solid #f7ad00;
  background-color: #414141;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-link.round {
  border-radius: 100%;
}

.social-link.label-round {
  border-radius: 10px;
}

.social-link-label {
  background-color: transparent;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.social-link > a {
  display: block;
  width: 22px;
  height: 22px;
  margin: auto;
  text-decoration: none;
  background: transparent;
}
.social-link > a > img {
  width: 100%;
  height: 100%;
  background: transparent;
}

/*profile description*/
.grid {
  display: flex;
  justify-content: center;
}

.profile-description {
  width: 90%;
  margin-top: -35px;
  padding: 0 5%;
  text-align: center;
}

.profile-tag {
  display: flex;
  width: fit-content;
  padding: 5px;
  background: #ffffff;
  border: 0.5px solid rgba(65, 65, 65, 0.2);
  border-radius: 5px;
  margin-right: 5px;
}

.profile-tag > img {
  margin: auto 0;
}

.profile-tag > span {
  font-size: 12px;
  width: 80px;
  text-overflow: ellipsis;
  margin: auto 0;
}

.profile-description > p {
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0px;
  color: #000000;
}

/*profile info*/
.profile-info {
  width: 90%;
  padding: 15px 0 8px 0;
  margin: 10px 5% 0 5%;
  border-top: 1px solid #eff1f3;
}

.profile-info > h4 {
  margin: 0;
  font-family: "GilroyMed";
  font-size: 18px;
}

.profile-info > .profile-details {
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 7px 0 0 0;
}

.profile-details > li {
  width: 100%;
  margin: 8px 0;
  display: flex;
}

.profile-details > li > img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.profile-details > li > span {
  margin-left: auto;
  font-family: Gilroy;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.16500000655651093px;
}

.profile-details > li > label {
  color: #b2b3b3;
  font-family: Gilroy;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.16500000655651093px;
}
